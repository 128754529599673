import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const LandingPage = React.lazy(() => import('./components/LandingPage'));
const PhotoUploadPage = React.lazy(() => import('./components/PhotoUploadPage'));
const PDFBookGenerator = React.lazy(() => import('./components/PDFBookGenerator'));
const HowItWorksPage = React.lazy(() => import('./components/HowItWorksPage'));
const GalleryPage = React.lazy(() => import('./components/GalleryPage'));
const SharedBookPage = React.lazy(() => import('./components/SharedBookPage'));

function App() {
  console.log('App component rendering');
  return (
    <Router>
      <div className="app-container">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/upload" element={<PhotoUploadPage />} />
            <Route path="/generate-pdf" element={<PDFBookGenerator />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/book/:bookId" element={<PDFBookGenerator />} />
            <Route path="/shared-book/:bookId" element={<SharedBookPage />} />
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;